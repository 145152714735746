export function timeSince(from) {
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  
    const seconds = secondsSinceEpoch - from;
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return timename(interval, "year");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return timename(interval, "month");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return timename(interval, "day");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return timename(interval, "hour");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return timename(interval, "minute");
    }
  
    return timename(seconds, "second");
  }
  
  const timename = (interval, timeframe) => {
    const floored = Math.floor(interval);
    const time = floored === 1 ? timeframe : `${timeframe}s`;
    return `${floored} ${time} ago`;
  };



export const saleConfig = {
  chart: {
    type: "scatter",
    zoomType: "xy",
    backgroundColor: "#1b222d",
    borderRadius: 8,
    // width: 440,
    // responsive: {
    //   rules: [{
    //     condition: {
    //       minWidth: 300
    //     }
    //   }]
    // }
  }, 

  title: {
    text: "Recent Sales",
    style: {
      color: "white",
      fontFamily: "Poppins",
    },
  },
  xAxis: {
    title: {
      enabled: true,
      text: "TIME",
    },
    startOnTick: false,
    endOnTick: true,
    showLastLabel: true,
    type: "datetime",
  },
  yAxis: {
    type: "logarithmic",
    title: {
      text: "Price in ETH",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    scatter: {
      marker: {
        radius: 5,
        states: {
          hover: {
            enabled: true,
            lineColor: "rgb(100,100,100)",
          },
        },
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    pointFormatter: function(){
      return `<div class="pointeer"><span>#${this.custom.tokenId}</span>
      <span>${this.y} ETH</span>
      <div><img src="${this.custom.image}" /></div>
    </div>`
    },
    style: {
      position: 'relative'
    }
  },
};

export const ethPerRank = {
  chart: {
    type: "scatter",
    zoomType: "xy",
    backgroundColor: "#1b222d",
    borderRadius: 8,
    // width: 440,
  },

  title: {
    text: "Sales/Listing per Rank",
    style: {
      color: "white",
      fontFamily: "Poppins",
    },
  },
  xAxis: {
    title: {
      enabled: true,
      text: "RARITY RANK",
    },
    startOnTick: false,
    endOnTick: false,
    showLastLabel: true,
  },
  yAxis: {
    type: "logarithmic",
    title: {
      text: "Price in ETH",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    scatter: {
      marker: {
        radius: 5,
        states: {
          hover: {
            enabled: true,
            lineColor: "rgb(100,100,100)",
          },
        },
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    pointFormatter: function(){
      return `<div class="pointeer"><span>#${this.custom.tokenId}</span>
      <span>${this.y} ETH</span><br/>
      <span>Rank ${this.x}</span>
      <div><img src="${this.custom.image}" /></div>
    </div>`
    },
    style: {
      position: 'relative'
    }
  }
}
