import { Heading, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Wrapper, PictureWrapper, Picture, TextWrapper } from "./Intro";

const PicWrapper = styled(PictureWrapper)`
  flex: 0.5;
  width: 100%;
`;

const SpecsWrapper = styled(Wrapper)`
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

const Specs = () => (
  <SpecsWrapper>
    <PicWrapper>
      <Picture>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nftninja-prod.appspot.com/o/spec2.png?alt=media&token=077a0a0f-6787-42e8-b686-45412d3012b1"
          alt="ape 1"
        />
      </Picture>
    </PicWrapper>
    <TextWrapper>
      <Heading
        color={"#F86663"}
        fontWeight={800}
        fontSize={"1.6em"}
        fontStyle={"italic"}
        padding="0 2%"
        marginBottom={'2%'}
      >
        SPECS
      </Heading>
      <Text fontWeight={400} lineHeight={"24px"} padding="0 2%">
        Each Elderly Ape is one of a kind, just like you and me. <br /> <br /> We have curated
        5,000 unique Elderly Apes and tokenized them with an ERC-721 token on
        the Ethereum Blockchain. <br /> <br /> Your Elderly Ape will live on the IPFS forever,
        but we like to call it their retirement home. <br /> <br /> The cost to mint will be
        0.055 ETH each.
      </Text>
    </TextWrapper>
  </SpecsWrapper>
);

export default Specs;
