import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Image = styled.div`
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`;

export const MintDateWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderImg = ({ children }) => (
  <Image>
    <img
      alt="Header"
      src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/earc-house-night-tiny.png?alt=media&token=c375159f-fce4-475b-bcd2-4a437a3f3127"
    />
    {children}
  </Image>
);

const HeaderImage = () => (
  <HeaderImg>
  </HeaderImg>
);

export const Panel = styled.div`
  padding: 2%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.span`
  font-size: 0.8em;
  font-style: italic;
  color: #d6d6d6;
  margin-bottom: 8px;
  font-weight: 200;
`;

const SoldOut = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 5%;
  }
  a {
    font-weight: 100;
    color: #f86663;
  }
`;

const MintDate = () => (
  <MintDateWrapper>
    <SoldOut>
      <div>SOLD OUT</div>
      <Button
        variant="outline"
        _hover={{ color: "#F86663" }}
        onClick={() => window.open("https://opensea.io/collection/earc")}
      >
        CHECK OPENSEA
      </Button>
    </SoldOut>
  </MintDateWrapper>
);

export default HeaderImage;
