import { getAnalytics, logEvent } from "firebase/analytics";
import { Content } from "../App";
import Header from "./Header";

import { Box, List, ListItem } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { FaEthereum } from "react-icons/fa";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { utils } from "ethers";
import { timeSince, ethPerRank, saleConfig } from "./../lib/helpers";
import styled from "@emotion/styled";
import Footer from "./Footer";
import { Headline } from "./Rarity";

const Wrapper = styled.div`
  padding: 1%;
  border-radius: 15px;
  background-color: #2c3643;
  border: solid 1px #2b3441;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 2%;
  
`;

const WrapperBox = styled.div`
  flex: 2;
`;

const WrapperChart = styled(WrapperBox)`
  min-width: 300px;
  @media (max-width: 580px) {
    margin: 0 2.5%;
  }
`;

const WrapperBoxSales = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0.5% 1%;
  @media (max-width: 580px) {
    margin: 0 2.5% 1%;
  }
  flex-wrap: wrap;
  flex: 3;
`;
const ListItemLive = ({
  date,
  address,
  image,
  price,
  rank,
  token_id: tokenId,
  ending_price,
  type,
}) => {
  const priceToTake = price || ending_price;
  const parseFloatPrice = priceToTake
    ? parseFloat(utils.formatUnits(priceToTake))
    : null;
  const newPrice = parseFloatPrice
    ? parseFloatPrice > 1000
      ? `${parseFloatPrice / 1000}K`
      : parseFloatPrice.toFixed(2)
    : null;

  return (
    <ListItem minW={280}>
      <ListLink
        target="_blank"
        rel="noreferrer"
        href={`https://opensea.io/assets/${address}/${tokenId}`}
      >
        <Image>
          <img src={image} alt={tokenId} />
        </Image>
        <Box paddingLeft="2.5%" alignItems="flex-start">
          {rank && (
            <Rank>
              Rank: <span>{rank}</span>
            </Rank>
          )}
          <TokenId>#{tokenId}</TokenId>
        </Box>
        <WrapperBack alignItems="flex-end">
          <PriceWrapper>
            <Label sale={type === "SALE" ? true : false}>{type}</Label>
            <Price>
              <FaEthereum /> {newPrice}
            </Price>
          </PriceWrapper>
          <Time>{timeSince(date.seconds)}</Time>
        </WrapperBack>
      </ListLink>
    </ListItem>
  );
};

const Rank = styled.p`
  font-weight: 300;
  color: #939ca5;
  span {
    font-weight: 600;
    color: #097eb0;
  }
`;

const WrapperBack = styled(WrapperBox)`
  display: flex;
      flex-direction: column;
      align-items: ${({ alignItems }) => alignItems};
      justify-content: normal;
      padding ${({ padding }) => (padding ? padding : 0)};
  `;

const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 5%;
  align-items: center;
`;

const Label = styled.div`
  color: ${({ sale }) => (sale ? "#097EB0" : "#a7f292")};
  border: 1px solid ${({ sale }) => (sale ? "#097EB0" : "#a7f292")};
  padding: 1px 8%;
  border-radius: 5px;
  margin: 0 3%;
  font-size: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
`;

const TokenId = styled.p`
  font-size: 0.8em;
`;

const Time = styled.div`
  color: #939ca5;
  font-style: italic;
  font-size: 0.8em;
`;

const Image = styled.a`
  max-width: 50px;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

const ListLink = styled.a`
  display: flex;
  padding: 2%;
  @media (max-width: 580px) {
    padding: 1%;
  }
  margin: 1%;
  background-color: #1b222d;
  border-radius: 8px;
  align-items: center;
  color: white;
  :hover {
    background-color: #171d26;
  }
`;

const ListWrapper = styled(WrapperBox)`
  max-height: 810px;
  @media (max-width: 1040px) {
    max-height: 400px;
    margin-bottom: 2%;
    min-width: 285px;
  }
  @media (max-width: 580px) {
    max-height: 200px;
    margin-bottom: 2%;
    min-width: 285px;
  }
  overflow: scroll;
  border-radius: 8px;
`;

const Stats = ({ fbIsInit, address, hasPayed }) => {
  const [listing, setListings] = useState([]);
  const [sales, setSales] = useState([]);

  useEffect(() => {
    if (fbIsInit) {
      const analytics = getAnalytics();
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: "/stats",
        page_title: "STATS",
      });
    }
  }, [fbIsInit]);

  useEffect(() => {
    let unsubSales = () => {};
    let unsubListings = () => {};
    if (fbIsInit) {
      const db = getFirestore();
      const last6Hours = new Date(Date.now() - 60 * 60 * 4 * 1000);
      const last48HourSales = new Date(Date.now() - 60 * 60 * 24 * 1000);

      const q = query(
        collection(
          db,
          "sales",
          "0x9ee36cd3e78badcaf0cbed71c824bd8c5cb65a8c",
          "sales"
        ),
        where("date", ">", last48HourSales),
        orderBy("date", "desc")
      );

      unsubSales = onSnapshot(q, (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          list.push(data);
        });
        setSales(list);
      });

      const qListings = query(
        collection(
          db,
          "listings",
          "0x9ee36cd3e78badcaf0cbed71c824bd8c5cb65a8c",
          "listings"
        ),
        where("date", ">", last6Hours),
        where("type", "==", "BUY_NOW"),
        orderBy("date", "desc")
      );

      unsubListings = onSnapshot(qListings, (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          list.push(data);
        });
        setListings(list);
      });
    }
    return () => {
      unsubListings();
      unsubSales();
    };
  }, [fbIsInit, address, hasPayed]);

  const salesGraphOptions = useMemo(() => {
    const salePlots = sales.reduce((acc, cur) => {
      if (
        cur.payment_token &&
        cur.payment_token.symbol &&
        (cur.payment_token.symbol === "ETH" ||
          cur.payment_token.symbol === "WETH")
      ) {
        if (!cur.price || !cur.date) {
          return acc;
        }
        const time = cur.date.seconds * 1000;
        const parseFloatPrice = parseFloat(utils.formatUnits(cur.price));
        const feedback = {
          x: time,
          y: parseFloatPrice,
          custom: {
            image: cur.image,
            tokenId: cur.token_id,
          },
        };
        return [...acc, feedback];
      }
      return acc;
    }, []);
    return {
      ...saleConfig,
      series: [
        {
          name: "Sales",
          color: "rgba(9, 126, 176, .5)",
          data: salePlots,
        },
      ],
    };
  }, [sales]);

  const perRankOptions = useMemo(() => {
    const salePlots = sales.reduce((acc, cur) => {
      if (
        cur.payment_token &&
        cur.payment_token.symbol &&
        (cur.payment_token.symbol === "ETH" ||
          cur.payment_token.symbol === "WETH")
      ) {
        if (!cur.price || !cur.rank) {
          return acc;
        }
        const rank = cur.rank;
        const parseFloatPrice = parseFloat(utils.formatUnits(cur.price));
        const feedback = {
          x: rank,
          y: parseFloatPrice,
          custom: {
            image: cur.image,
            tokenId: cur.token_id,
          },
        };
        return [...acc, feedback];
      }
      return acc;
    }, []);
    const listingsPlots = listing.reduce((acc, cur) => {
      if (
        cur.payment_token &&
        cur.payment_token.symbol &&
        (cur.payment_token.symbol === "ETH" ||
          cur.payment_token.symbol === "WETH")
      ) {
        if (!cur.ending_price || !cur.rank) {
          return acc;
        }
        const rank = cur.rank;
        const parseFloatPrice = parseFloat(utils.formatUnits(cur.ending_price));
        const feedback = {
          x: rank,
          y: parseFloatPrice,
          custom: {
            image: cur.image,
            tokenId: cur.token_id,
          },
        };
        return [...acc, feedback];
      }
      return acc;
    }, []);
    return {
      ...ethPerRank,
      series: [
        {
          name: "Listings",
          color: "rgba(167, 242, 146, .5)",
          data: listingsPlots,
        },
        {
          name: "Sales",
          color: "rgba(9, 126, 176, .5)",
          data: salePlots,
        },
      ],
    };
  }, [sales, listing]);
  return (
    <Content>
      <Header />
      <Headline textAlign={"center"} marginBottom={"2%"}>
        STATS
      </Headline>
        <Wrapper>
          <WrapperBoxSales>
            <ListWrapper>
              <List minW={280}>
                {listing.map((item) => (
                  <ListItemLive key={item.id} {...item} type="LISTING" />
                ))}
              </List>
            </ListWrapper>
            <ListWrapper>
              <List>
                {sales.map((item) => (
                  <ListItemLive key={item.id} {...item} type="SALE" />
                ))}
              </List>
            </ListWrapper>
          </WrapperBoxSales>
          <WrapperChart>
            <Chart options={salesGraphOptions} />
            <Chart options={perRankOptions} />
          </WrapperChart>
        </Wrapper>
      <Footer />
    </Content>
  );
};


const ChartWrapper = styled.div`
  margin-bottom: 2%;
`;
const Chart = ({ options }) => {
  return (
    <ChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
};

export default Stats;
