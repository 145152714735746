import { UnorderedList, ListItem } from "@chakra-ui/react";

import roadmap from "./../content/roadmap.json";
import { padding, PictureWrapper, Wrapper as Content } from "./Intro";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  ${padding}
  width: 95%;
`;

const Headline = styled.h2`
  font-size: 1.6em;
  ${padding}
  font-weight: 800;
  font-style: italic;
  color: #F86663;
`;

const MyListItem = styled(ListItem)`
  h3 {
    font-weight: 600;
  }
  p {
    font-weight: 300;
  }
`;

const MyPictureWrapper = styled(PictureWrapper)`
  justify-content: center;
  margin-bottom: 2%;
`;

const MyPic = styled.div`
min-width: 180px;
`

const Roadmap = () => (
  <Wrapper>
    <Headline>RETIREMENT PLAN</Headline>
    <Content>
      <UnorderedList marginBottom='2%' minWidth={250} flex={2} spacing={10} fontSize={16}>
        {roadmap.map((roadmappoint) => {
          return (
            <MyListItem key={roadmappoint.key}>
              <h3>{roadmappoint.header}</h3>
              <p>{roadmappoint.content}</p>
            </MyListItem>
          );
        })}
      </UnorderedList>
      <MyPictureWrapper>
        <MyPic>
          <img
            alt="bingo-card"
            src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/bingo%20transparent.png?alt=media&token=74fcc4b7-bee6-4d67-a410-cf32fcaac9d9"/>
        </MyPic>
      </MyPictureWrapper>
    </Content>
  </Wrapper>
);

export default Roadmap;
