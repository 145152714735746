export const INFURA_ID = 'd63b9802f8f549939e2246ad14a1f65c'
export const BLOCKNATIVE_DAPPID = '905e8b80-cd58-4f4c-b79d-3baa540c648b';

export const NETWORK = 'mainnet'

export const START_PRE_SALE = 1640120400;
export const END_PRE_SALE = 1640203200;
export const START_SALE_STANDARD = 1640206800;

export const PRICE = 0.055;

const DB = {
  rinkeby: 'contract-test',
  mainnet: 'contract'
}

export const DATABASE = DB[NETWORK];
export const NETWORKS = {
    localhost: {
      name: 'localhost',
      color: '#666666',
      chainId: 31337,
      blockExplorer: undefined,
      rpcUrl: 'https://localhost:8545',
    },
    mainnet: {
      name: 'mainnet',
      color: '#ff8b9e',
      chainId: 1,
      rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      blockExplorer: 'https://etherscan.io/',
    },
    kovan: {
      name: 'kovan',
      color: '#7003DD',
      chainId: 42,
      rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
      blockExplorer: 'https://kovan.etherscan.io/',
      faucet: 'https://gitter.im/kovan-testnet/faucet', // https://faucet.kovan.network/
    },
    rinkeby: {
      name: 'rinkeby',
      color: '#e0d068',
      chainId: 4,
      rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
      faucet: 'https://faucet.rinkeby.io/',
      blockExplorer: 'https://rinkeby.etherscan.io/',
    },
    ropsten: {
      name: 'ropsten',
      color: '#F60D09',
      chainId: 3,
      faucet: 'https://faucet.ropsten.be/',
      blockExplorer: 'https://ropsten.etherscan.io/',
      rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
    },
    goerli: {
      name: 'goerli',
      color: '#0975F6',
      chainId: 5,
      faucet: 'https://goerli-faucet.slock.it/',
      blockExplorer: 'https://goerli.etherscan.io/',
      rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
    },
    xdai: {
      name: 'xdai',
      color: '#48a9a6',
      chainId: 100,
      price: 1,
      gasPrice: 1000000000,
      rpcUrl: 'https://dai.poa.network',
      faucet: 'https://xdai-faucet.top/',
      blockExplorer: 'https://blockscout.com/poa/xdai/',
    },
    matic: {
      name: 'matic',
      color: '#2bbdf7',
      chainId: 137,
      price: 1,
      gasPrice: 1000000000,
      rpcUrl: 'https://rpc-mainnet.maticvigil.com',
      faucet: 'https://faucet.matic.network/',
      blockExplorer: 'https://explorer-mainnet.maticvigil.com//',
    },
    mumbai: {
      name: 'mumbai',
      color: '#92D9FA',
      chainId: 80001,
      price: 1,
      gasPrice: 1000000000,
      rpcUrl: 'https://rpc-mumbai.maticvigil.com',
      faucet: 'https://faucet.matic.network/',
      blockExplorer: 'https://mumbai-explorer.matic.today/',
    },
    localArbitrum: {
      name: 'localArbitrum',
      color: '#50a0ea',
      chainId: 153869338190755,
      blockExplorer: '',
      rpcUrl: `http://localhost:8547`,
    },
    localArbitrumL1: {
      name: 'localArbitrumL1',
      color: '#50a0ea',
      chainId: 44010,
      blockExplorer: '',
      rpcUrl: `http://localhost:7545`,
    },
    rinkebyArbitrum: {
      name: 'Arbitrum Testnet',
      color: '#50a0ea',
      chainId: 421611,
      blockExplorer: 'https://rinkeby-explorer.arbitrum.io/#/',
      rpcUrl: `https://rinkeby.arbitrum.io/rpc`,
    },
    arbitrum: {
      name: 'Arbitrum',
      color: '#50a0ea',
      chainId: 42161,
      blockExplorer: 'https://explorer.arbitrum.io/#/',
      rpcUrl: `https://arb1.arbitrum.io/rpc`,
      gasPrice: 0,
    },
    localOptimismL1: {
      name: 'localOptimismL1',
      color: '#f01a37',
      chainId: 31337,
      blockExplorer: '',
      rpcUrl: 'http://localhost:9545',
    },
    localOptimism: {
      name: 'localOptimism',
      color: '#f01a37',
      chainId: 420,
      blockExplorer: '',
      rpcUrl: 'http://localhost:8545',
      gasPrice: 0,
    },
    kovanOptimism: {
      name: 'kovanOptimism',
      color: '#f01a37',
      chainId: 69,
      blockExplorer: 'https://kovan-optimistic.etherscan.io/',
      rpcUrl: `https://kovan.optimism.io`,
      gasPrice: 0,
    },
    optimism: {
      name: 'optimism',
      color: '#f01a37',
      chainId: 10,
      blockExplorer: 'https://optimistic.etherscan.io/',
      rpcUrl: `https://mainnet.optimism.io`,
    },
  };

  export const CHAINDATA = NETWORKS[NETWORK];
