import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { Content } from "../App";
import Header from "./Header";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";

import styled from "@emotion/styled";
import CardComponent from "./CardComponent";
import Footer from "./Footer";

const Inputwrapper = styled.div`
  margin-bottom: 5%;
`;

const Rarity = ({ fbIsInit }) => {
  const [show, setShow] = useState(40);
  const [items, setItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [tokenId, setTokenId] = useState(null);
  const [normalize, setNormalize] = useState(false);

  const mySeletedValues = useMemo(() => {
    return selectedValue.map((myItem) =>
      myItem[0]
        .split("&")
        .join("")
        .split("'")
        .join("")
        .split("-")
        .join("")
        .split(" ")
        .join("")
        .replace("100", "Hundret")
    );
  }, [selectedValue]);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (fbIsInit) {
      const analytics = getAnalytics();
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: "/rarity",
        page_title: "RARITY",
      });
    }
  }, [fbIsInit]);

  useEffect(() => {
    let unSub;
    if (fbIsInit) {
      const db = getFirestore();

      const queryStuff = [
        collection(db, "items"),
        orderBy("rank", "asc"),
        limit(show),
      ];

      const cats = tokenId
        ? []
        : mySeletedValues.map((cat) => where(`allValues.${cat}`, "==", true));
      const tokenIdArray = tokenId
        ? [where("index", "==", parseInt(tokenId))]
        : [];
      const q = query(...queryStuff, ...cats, ...tokenIdArray);

      unSub = onSnapshot(q, (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          list.push(data);
        });
        setItems(list);
      });
    }
    return () => {
      if (unSub) {
        unSub();
      }
    };
  }, [fbIsInit, show, mySeletedValues, tokenId]);

  useEffect(() => {
    let unSub;
    if (fbIsInit) {
      const db = getFirestore();
      let q;
      q = query(collection(db, "categories"));

      unSub = onSnapshot(q, (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          list.push(data);
        });
        setCategories(list);
      });
    }
    return () => {
      if (unSub) {
        unSub();
      }
    };
  }, [fbIsInit]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setShow((_show) => _show + 20);
    }
  };
  const loader = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    // initialize IntersectionObserver and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  return (
    <Content>
      <Header />
      <Wrapper>
        <Headline textAlign={"center"} marginBottom={"2%"}>
          RARITY
        </Headline>
        <Flex
          width={"100%"}
          display={"flex"}
          flexWrap={"wrap"}
          marginBottom={"5%"}
        >
          <Flex
            flex={1.1}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            padding={"0 1%"}
          >
            <Inputwrapper>
              <Input
                value={tokenId ? tokenId : ""}
                placeholder="TokenID"
                type="number"
                onChange={(e) => {
                  if (e.target.value) {
                    setTokenId(e.target.value);
                  } else {
                    setTokenId(null);
                  }
                  console.log(e.target.value);
                }}
              />
            </Inputwrapper>
            <Accordion allowMultiple width={"100%"}>
              {categories.map((item) => {
                const name = item.name;
                const children = item.children.map((myItem) => {
                  const seleted = selectedValue.find(
                    (item) => item[0] === myItem
                  )
                    ? true
                    : false;
                  return (
                    <Row
                      key={myItem}
                      itemName={myItem}
                      onClick={() => {
                        const newIn = [myItem, name];
                        setSelectedValue((prev) => {
                          const newPrev = prev.filter(
                            (item) => item[1] !== name
                          );
                          if (seleted) {
                            return newPrev;
                          }
                          return [...newPrev, newIn];
                        });
                        setShow(40);
                      }}
                      selected={seleted}
                    />
                  );
                });
                return (
                  <AccItem header={name} key={name}>
                    {children}
                  </AccItem>
                );
              })}
            </Accordion>
            <div>
              <Button
                onClick={() => {
                  setSelectedValue([]);
                  setTokenId(null);
                }}
                _hover={{ bg: "#F86663" }}
                alignItems={"center"}
                margin="2% 0"
                variant={"outline"}
              >
                RESET FILTER
              </Button>
            </div>
            <FormControl display="flex" alignItems="center" margin="4% 0">
              <FormLabel htmlFor="normalize" mb="0">
                Normalize Ranking
              </FormLabel>
              <Switch
                id="normalize"
                onChange={(e) => setNormalize((prev) => !prev)}
              />
            </FormControl>
          </Flex>
          <Flex flex={5} flexDirection={"column"} minW={280}>
            <Flex
              width={"100%"}
              display={"flex"}
              flexWrap={"wrap"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {items.map((item) => (
                <CardComponent
                  score={item.score}
                  rank={normalize ? item.rankNormalized : item.rank}
                  image={item.image}
                  index={item.index}
                  key={item.index}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
      <div className="loading" ref={loader} />
      <Footer />
    </Content>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 0 5%;
`;

export const Headline = styled(Heading)`
  div {
    font-size: 0.4em;
    opacity: 0.5;
  }
`;

const TraitItemContainer = styled.a`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
  border-radius: 10px;
  background-color: #293341;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "600" : "300")};
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const TraitItem = styled.div`
  border-radius: 10px;
  padding: 2% 2% 2%;
  flex: 8;
  background-color: ${({ selected }) => (selected ? "#F86663" : "#323e4e")};
  padding: 1% 1% 1% 2%;
  color: ${({ selected }) => (selected ? "white" : "white")};
  &:hover {
    background-color: ${({ selected }) => (selected ? "#F86663" : "#303B4B")};
  }
`;

const Row = ({ itemName, onClick, selected }) => (
  <TraitItemContainer selected={selected} onClick={onClick}>
    <TraitItem selected={selected}> {itemName}</TraitItem>
  </TraitItemContainer>
);

const AccItem = ({ header, children }) => {
  return (
    <AccordionItem
      borderRadius="15px"
      backgroundColor="#252e3a"
      marginBottom="3%"
      border="0"
    >
      <div>
        <AccordionButton backgroundColor="#252e3a" borderRadius="15px">
          <Box flex="1" textAlign="left" fontSize="0.9em">
            {header}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </div>
      <AccordionPanel padding="2%" fontSize={"0.9em"}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Rarity;
