import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Link } from "@chakra-ui/react";
import { GiSnake } from "react-icons/gi";
import OpenSeaTransparent from "./OpenSeaIcon";
import React from "react";

const Wrapper = styled.div`
  width: 90%;
  margin: 0 5%;
  display: flex;
  padding: 2.5%;
  border-top: 1px white solid;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  max-width: 100px;
  img {
    width: 100%;
  }
`;

const Footer = React.forwardRef((props,ref) => (
  <Wrapper ref={ref}>
    <Link
      rel="noreferrer"
      target="_blank"
      href="https://www.instagram.com/earclubnft/"
      color="white"
      margin="0 2%"
      _hover={{ color: "#F86663" }}
    >
      <Icon width={6} height={6} as={FaInstagram} />
    </Link>
    <Link
      rel="noreferrer"
      target="_blank"
      href="https://twitter.com/EARClubNFT"
      color="white"
      margin="0 2%"
      _hover={{ color: "#F86663" }}
    >
      <Icon width={6} height={6} as={FaTwitter} />
    </Link>
    <ImageWrapper>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/logo-ear.png?alt=media&token=bc7990cb-f0d9-4264-9931-aa10dcc5cd50"
        alt="logo"
      />
    </ImageWrapper>
    <Link
      rel="noreferrer"
      target="_blank"
      href="https://discord.gg/earc"
      margin="0 2%"
      color="white"
      _hover={{ color: "#F86663" }}
    >
      <Icon width={6} height={6} as={FaDiscord} />
    </Link>
    <Link
      _hover={{ color: "#F86663" }}
      rel="noreferrer"
      target="_blank"
      href="https://opensea.io/collection/earc"
      margin="0 2%"
      color="white"
    >
      <Icon width={6} height={6} margin="0 4% 0 2%" as={OpenSeaTransparent} />
    </Link>
  </Wrapper>
));

export default Footer;
