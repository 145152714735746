import team from "./../content/team.json";
import styled from "@emotion/styled";

const Header = styled.h2`
  margin: 2.5%;
  text-align: center;
  font-size: 1.6em;
  font-weight: 800;
  font-style: italic;
  color: #F86663;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 2.5%;
  margin-bottom: 4%;
`;

const Items = styled.div`
  display: flex;
  padding: 0 1.5%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Item = styled.a`
  margin: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  color: inherit;
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  min-width: 111px;
  max-width: 220px;
  margin-bottom: 5px;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const Title = styled.span`
color: #D6D6D6;
font-size: 0.7em;
`

const Team = () => (
  <Wrapper>
    <Header>THE TEAM</Header>
    <Items>
      {team.map((teammate) => {
        return (
          <Item key={teammate.name} target="_blank" rel="noreferrer" href={teammate.link}>
            <ImageWrapper>
              <img alt={teammate.name} src={teammate.image} />
            </ImageWrapper>
            <span>{teammate.name}</span>
            <Title>{teammate.title}</Title>
          </Item>
        );
      })}
    </Items>
  </Wrapper>
);

export default Team;
