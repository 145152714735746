import { Heading, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { flex } from "./Header";
export const Wrapper = styled.div`
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
`;

export const padding = css`
  padding: 0 2.5%;
`;

export const TextWrapper = styled.div`
  flex: 1;
  ${padding}
  min-width: 300px;
  margin-bottom: 4%;
`;

export const PictureWrapper = styled.div`
  ${padding}
  ${flex}
margin-bottom: 4%;
`;

export const Picture = styled.div`
  ${padding}
  min-width: 125px;
  img {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }
`;

const Intro = () => (
  <>
    <Wrapper>
      <TextWrapper>
        <Heading        
          fontWeight={800}
          fontSize={"1.6em"}
          marginTop={"2%"}
          marginBottom={"2%"}
          color={"#F86663"}
          fontStyle={'italic'}
     
        >
          !RETIRE. WHAT IS EARC?
        </Heading>
        <Text fontWeight={400} lineHeight={'24px'}>
          Elderly Apes Retirement Club is a 5,000 derivative NFT collection. Why Elderly Apes you ask? <br /><br/>
          Well, for those of us who are “old at heart” or who are slowly
          starting to feel our age creeping up on us, these Apes are, simply,
          relatable. Our collection is playful in nature and was designed with
          the intent to delight through our art and with the desire to bring a
          chuckle, or at least a smile, to all audiences. We’re all in the
          process of growing old - why not add a little humor while we're at it?
        </Text>
      </TextWrapper>
      <PictureWrapper>
        <Picture>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/ape3-small.png?alt=media&token=7d2ac5af-ec35-4424-b80c-62774966bfe9"
            alt="ape 1"
          />
        </Picture>
        <Picture>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/headerape2-tiny%2B.png?alt=media&token=0fd2564f-cf07-4e59-9e5f-1b19fdf7968d"
            alt="ape 2"
          />
        </Picture>
      </PictureWrapper>
    </Wrapper>
  </>
);

export default Intro;
