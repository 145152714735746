import { Flex, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import OpenSeaTransparent from "./OpenSeaIcon";

const Card = styled.div`
    background-color: rgb(44, 54, 67);
    padding: 0 1% 1%;
    margin: 1%;
    border-radius: 15px;
    border: 0.5px solid rgb(43, 52, 65);
`

const RankWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  color: white;
`;

const RankNumber = styled.div`
  padding: 1% 20% 3%;
  background-color: #F86663;
  border-radius: 0 0 20px 20px;
  font-size: 0.6em;
  font-weight: 800;
  font-stretch: normal;
`;

const Image = styled.div`
    max-width: 150px;
    margin-bottom: 8px;
    img {
        border-radius: 8px;

        width: 100%;
        height: auto;
    }
`


const Rank = ({ number }) => (
  <RankWrapper >
    <RankNumber >RANK #{number}</RankNumber>
  </RankWrapper>
);

const CardComponent = ({ image, index, rank,score }) => {
  const openSeaLink = `https://opensea.io/assets/0x9ee36cD3E78bAdcAF0cBED71c824bD8C5Cb65a8C/${index}`;

  return (
    <Card>
      <Rank number={rank} />
      <Image>
        <img alt={`token-${index}`} src={image} />
      </Image>
      <Flex display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={'1%'}>
        <div>
        <Text fontSize={'0.8em'} color="#939ca5">#{index}</Text>
        <Text fontSize={'0.6em'} >Score: {score}</Text>
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href={openSeaLink}>
            <OpenSeaTransparent height={20} width={20} />
          </a>
        </div>
      </Flex>
    </Card>
  );
};

export default CardComponent;
