import { Button, Heading, Icon, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import OpenSeaTransparent from "./OpenSeaIcon";

const Wrapper = styled.div`
  width: 100%;
  padding: 2%;
  background-color: #f86663;
  display: flex;
  flex-wrap: wrap;
`;

const TextSection = styled.div`
  flex: 1;
  text-align: center;
  padding: 0 10%;
`;

const BuySection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SoldOut = () => (
  <Wrapper>
    <TextSection>
      <Heading fontWeight={800}>BUY AN ELDER</Heading>
      <Text>
        We sold out. If you want to get an Elder too, checkout Opensea.
      </Text>
    </TextSection>
    <BuySection>
      <Button
      size='lg'
        _hover={{ bg: "black" }}
        onClick={() => window.open("https://opensea.io/collection/earc")}
        rightIcon={
          <Icon
            width={5}
            height={5}
            margin="0 4% 0 2%"
            as={OpenSeaTransparent}
          />
        }
        backgroundColor={"black"}
      >
        BUY AN ELDER
      </Button>
    </BuySection>
  </Wrapper>
);

export default SoldOut;
